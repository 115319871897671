import { default as indexuHXkDOdyD7Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/about-company/index.vue?macro=true";
import { default as _91slug_93OoYKLtcrg6Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/actions/[slug].vue?macro=true";
import { default as indexkutL0O6rYIMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/actions/index.vue?macro=true";
import { default as _91slug_932GBMgkhp2DMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/blog/[slug].vue?macro=true";
import { default as indexPgZwXKjgidMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/blog/index.vue?macro=true";
import { default as _91slug_93S70lD2ePl2Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/bluda/[slug].vue?macro=true";
import { default as indexCNvcNLv8mxMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/bluda/index.vue?macro=true";
import { default as status50RKcrI89OMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/cabinet/payment/status.vue?macro=true";
import { default as index3fICk0mS5NMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/cart/index.vue?macro=true";
import { default as successkcL554RkqeMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/cart/success.vue?macro=true";
import { default as _91slug_93QbD6Gd9bSWMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/categories/[slug].vue?macro=true";
import { default as indexNkZp2RXUmIMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/categories/index.vue?macro=true";
import { default as checking_45partnersPsvCIE7tfhMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/checking-partners.vue?macro=true";
import { default as contacts7DjW1lZ32JMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/contacts.vue?macro=true";
import { default as indexQPxNpYsevAMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/delete-account/index.vue?macro=true";
import { default as indexsZnLzK525MMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/index.vue?macro=true";
import { default as indexPuqmbPBu4TMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/info-for-partners/index.vue?macro=true";
import { default as _91slug_930RNF7i17zxMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/kuhni/[slug].vue?macro=true";
import { default as indexJ3QvWw9BcDMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/kuhni/index.vue?macro=true";
import { default as offermz0NjwRaNnMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/offer.vue?macro=true";
import { default as indexT8wStKh35jMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/partner-apply-from-mobile/index.vue?macro=true";
import { default as privacyNYSRdbCspnMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/privacy.vue?macro=true";
import { default as addreses7Wc1gVaobhMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/addreses.vue?macro=true";
import { default as indexYuCGVbWPcxMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/index.vue?macro=true";
import { default as infoBELmk6yhpcMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/info.vue?macro=true";
import { default as reviews0njFoJRkIvMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/reviews.vue?macro=true";
import { default as _91slug_93yD18u5L1IUMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/restaurants/[slug].vue?macro=true";
import { default as index7fW9izRT4vMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/restaurants/index.vue?macro=true";
import { default as searchQcdq2rq1rFMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/search.vue?macro=true";
import { default as standarts_45partnersNjXCaWFL6rMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/standarts-partners.vue?macro=true";
import { default as terms_45of_45usevCTbHH0xJgMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/terms-of-use.vue?macro=true";
import { default as indexUrSGdDgefSMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/wish/index.vue?macro=true";
export default [
  {
    name: "about-company",
    path: "/about-company",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/about-company/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-slug",
    path: "/actions/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/actions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "bluda-slug",
    path: "/bluda/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/bluda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bluda",
    path: "/bluda",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/bluda/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-payment-status",
    path: "/cabinet/payment/status",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/cabinet/payment/status.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: index3fICk0mS5NMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-success",
    path: "/cart/success",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "checking-partners",
    path: "/checking-partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/checking-partners.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-for-partners",
    path: "/info-for-partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/info-for-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "kuhni-slug",
    path: "/kuhni/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/kuhni/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kuhni",
    path: "/kuhni",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/kuhni/index.vue").then(m => m.default || m)
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: "partner-apply-from-mobile",
    path: "/partner-apply-from-mobile",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/partner-apply-from-mobile/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-addreses",
    path: "/profile/addreses",
    meta: addreses7Wc1gVaobhMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/addreses.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexYuCGVbWPcxMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-info",
    path: "/profile/info",
    meta: infoBELmk6yhpcMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-reviews",
    path: "/profile/reviews",
    meta: reviews0njFoJRkIvMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/profile/reviews.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug()",
    meta: _91slug_93yD18u5L1IUMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/restaurants/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "standarts-partners",
    path: "/standarts-partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/standarts-partners.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "wish",
    path: "/wish",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241114044230/pages/wish/index.vue").then(m => m.default || m)
  }
]